import React, { useEffect } from 'react';

const ScriptComponent = () => {
  useEffect(() => {
    // Check if the script is already loaded
    const scriptId = "koljs";
    if (document.getElementById(scriptId)) {
      return;
    }

    // Create script element
    const script = document.createElement('script');
    script.src = "https://b.kickoffpages.com/2.2.0/kol.js";
    script.id = scriptId;
    script.async = true;
    script.setAttribute("data-campaign-id", "179224");

    // Append script to the body
    document.body.appendChild(script);

    // Cleanup function to remove script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div data-kol-snippet="embedpage" data-kolPageId="382620" className="kol-embed-page-frame default"></div>
    </div>
  );
};

export default ScriptComponent;
