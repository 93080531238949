import React, { useRef } from 'react'
import Header from "./Components/Header"
import Hero from "./Components/Hero"
import AltHero from "./Components/AltHero"
import WhyBuild from "./Components/WhyBuild"
import WaitlistBenefits from "./Components/WaitlistBenefits"
import Footer from "./Components/Footer"
import './index.css';

function App() {
  const buildRef = useRef()
  const joinRef = useRef()
  const scrollToBuild = () => {
    buildRef.current?.scrollToTarget({ behavior: 'smooth' })
  }
  const scrollToJoin = () => {
    joinRef.current?.scrollToTarget({ behavior: 'smooth' })
  }
  return (
    <div className="App">
      <Header onBuildLinkClick={scrollToBuild} onJoinLinkClick={scrollToJoin} />
      <AltHero />
      <WhyBuild ref={buildRef} />
      <WaitlistBenefits ref={joinRef} />
      <Footer />
     
    </div>
  );
}

export default App;
