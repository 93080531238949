import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import GM from "../gm.jpg"


const WhyBuild = forwardRef((props, ref) => {
  const targetRef = useRef()
  useImperativeHandle(ref, () => ({
    scrollToTarget: () => {
      targetRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }));
  return (
    <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8" ref={targetRef} >
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
      <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
      <div className="mx-auto max-w-2xl lg:max-w-4xl">
        
        <figure className="mt-10">
          <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
          <p>Why I'm Building This</p><br />
            <p>
              “While building and using AI agents and multi-agent workflows myself from the command line, I found it tedious and difficult to manage all of them and track their outputs in one place. <br /><br />

That's why I've created a tool that I first use myself and soon will share with you. If it doesn't at least 10x your AI productivity then it's not ready yet. Ultimately, I expect it to help someone, maybe you or me, build the first 1-person Fortune 500 company.<br /><br />

Good luck!”
            </p>
          </blockquote>
          <figcaption className="mt-10">
            <img
              className="mx-auto h-10 w-10 rounded-full"
              src={GM}
              alt=""
            />
            <div className="mt-4 flex items-center justify-center space-x-3 text-base">
              <div className="font-semibold text-gray-900">Greg Marlin</div>
              <svg viewBox="0 0 2 2" width={3} height={3} aria-hidden="true" className="fill-gray-900">
                <circle cx={1} cy={1} r={1} />
              </svg>
              <div className="text-gray-600">Co-Founder of CEO.ai</div>
            </div>
          </figcaption>
        </figure>
      </div>
    </section>
  )
}

)


export default WhyBuild